"use server";
import { getBrand, getCoinsSummary } from "../../apis/server_apis";
import { Header } from "~/types";
import { getCookie } from "vinxi/http";
import { redirect } from "@solidjs/router";
import { VoucherProduct } from "../../types/search";
import { APIError } from "~/utils/fetch";
import { getRequestEvent } from "solid-js/web";
import { getPlutoReceiverSessionIdStorageKey } from "~/components/gifting/pluto-receiver/pluto-receiver-landing/pluto_reveal_route_data";

export type PlutoOrderRouteData = {
  coinsCount: number;
  brand: VoucherProduct;
  logoUrl?: string;
};

export type PlutoOrderRouteDataProps = {
  giftingKey: string;
  brandKey: string;
};

export async function getPlutoOrderRouteData(props: PlutoOrderRouteDataProps) {
  let sessionId = getCookie(
    getPlutoReceiverSessionIdStorageKey(props.giftingKey)
  );
  if (!sessionId) {
    throw redirect("/pluto/reveal/" + props.giftingKey);
  }

  let coinsCount$P = getCoinsSummary(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let getVoucherProduct$P = getBrand(
    props.brandKey,
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  try {
    const resolved = await Promise.all([coinsCount$P, getVoucherProduct$P]);

    let coinsSummary = resolved[0];
    let brand = resolved[1];

    let logoUrl: string | undefined = getRequestEvent()?.locals.clientLogoUrl;

    return {
      coinsCount: coinsSummary.totalAvailable,
      brand: brand,
      logoUrl: logoUrl,
    };
  } catch (error) {
    if (error instanceof APIError) {
      if (error.code === 401) {
        throw redirect("/pluto/reveal/" + props.giftingKey);
      }
    }
  }
}
