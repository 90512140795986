import { cache, createAsync, useParams } from "@solidjs/router";
import { onMount, Show } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { PlutoOrderComponent } from "~/components/gifting/pluto-receiver/pluto-receiver-order/pluto_order_component";
import { getPlutoOrderRouteData } from "~/server/data/personalised_gifting/pluto_order_route_data";
import { BrandDetailResponse } from "~/server/types/gift";
import attachPopStateListener from "~/utils/popstate_listener";

const BrandCollectionOrderRoute$C = cache(
  getPlutoOrderRouteData,
  "BrandCollectionOrderRoute"
);

export default function BrandCollectionOrderRoute() {
  const params = useParams();
  const orderId = params.orderId as string;
  const brandKey = params.brandKey$ as string;
  const giftingKey = params.giftingKey$ as string;

  const routeData = createAsync(
    async () => {
      return await BrandCollectionOrderRoute$C({
        giftingKey,
        brandKey,
      });
    },
    { deferStream: true }
  );

  onMount(() => {
    attachPopStateListener();
  });

  return (
    <>
      <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />

      <Show when={routeData()}>
        <PlutoOrderComponent
          orderId={orderId}
          giftingKey={giftingKey}
          coinsCount={routeData()!.coinsCount}
          logoUrl={routeData()!.logoUrl}
          brand={
            {
              brandId: routeData()!.brand.externalId,
              brandKey: routeData()!.brand.brandKey,
              brandName: routeData()!.brand.name,
              cardBackgroundColor:
                routeData()!.brand.voucherProductMetadata.cardBackgroundColor,
              deeplink:
                routeData()!.brand.voucherProductMetadata
                  .addVoucherBrandPageUrl,
              heroImageUrl:
                routeData()!.brand.voucherProductMetadata.heroImageUrl,
              plainLogoUrl:
                routeData()!.brand.voucherProductMetadata.plainLogoUrl,
              tncResId: routeData()!.brand.voucherProductMetadata.tncResId,
            } as BrandDetailResponse
          }
        />
      </Show>
    </>
  );
}
