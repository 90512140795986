import {
  createEffect,
  createMemo,
  createSignal,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { getStoreOrderDetails } from "~/data/products";
import { getCoinsSummary } from "~/server/apis/client_apis";
import { OrderDetails, VoucherRedemptionType } from "~/server/types/order";
import { Header } from "~/types";
import { APIError } from "~/utils/fetch";
import { ThreeDotLoader } from "~/widgets/button";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";
import GiftCardGenerationInProgressComponent from "../../brand-collection-order/generating";
import { GiftRootV3 } from "../../brand-collection-order/order_sucess_gift_v3";
import GiftCardGenerationFailedComponent from "../../brand-collection-order/failed";
import { getCookie } from "~/utils/client_cookie";
import { getPlutoReceiverSessionIdStorageKey } from "../pluto-receiver-landing/pluto_reveal_route_data";
import {
  PlutoBalancePill,
  PlutoCloseButton,
} from "../pluto_receiver_components";
import { A, useNavigate } from "@solidjs/router";
import PlutoBranding from "../pluto_branding";

export type PlutoOrderProps = {
  orderId: string;
  giftingKey: string;
  brand: BrandDetailResponse;
  coinsCount: number;
  logoUrl?: string;
};

export function PlutoOrderComponent(props: PlutoOrderProps) {
  const [order, setOrder] = createSignal<OrderDetails | undefined>();
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);
  const [showHeader, setShowHeader] = createSignal(false);
  const navigate = useNavigate();

  let orderPollInterval: NodeJS.Timeout | undefined;

  async function setOrderStatus(orderId: string, sessionId: string) {
    try {
      setOrder(
        await getStoreOrderDetails(orderId, {
          [Header.SessionId]: sessionId,
        })
      );
    } catch (error) {
      if (error instanceof APIError) {
        if (error.code === 401) {
          navigate("/pluto/reveal/" + props.giftingKey);
        }
      }
    }
  }

  const setupOrderPolling = async (orderId: string) => {
    const sessionId =
      getCookie(getPlutoReceiverSessionIdStorageKey(props.giftingKey)) ?? "";
    await setOrderStatus(orderId, sessionId);

    orderPollInterval = setInterval(async () => {
      await setOrderStatus(orderId, sessionId);
    }, 5000);
  };

  onMount(async () => {
    await setupOrderPolling(props.orderId);
  });

  createEffect(() => {
    if (!order()) return;

    if (
      order()?.status != "INITIALISED" &&
      order()?.status != null &&
      order()?.status != "PROCESSING"
    ) {
      clearInterval(orderPollInterval);
    }
  });

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  const getCoins = createMemo<number>(() => {
    return latestCoinsBalance() ?? 0;
  });

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  return (
    <>
      <Show
        fallback={
          <div class="flex h-full w-full items-center justify-center">
            <ThreeDotLoader color="#fff" />
          </div>
        }
        when={order()}
      >
        <Switch>
          <Match
            when={
              order()?.status == "PROCESSING" ||
              order()?.status === "INITIALISED"
            }
          >
            <GiftCardGenerationInProgressComponent />
          </Match>
          <Match when={order()?.status == "COMPLETED"}>
            {buildHeader()}
            <GiftRootV3
              buildBranding={() => <PlutoBranding />}
              isPreview={false}
              showGuidelines={false}
              brand={props.brand}
              voucher={getVoucherDetails()}
              amount={order()!.amount}
              description={`${order()?.orderProductDetails.voucherProductTitle} gift card is yours`}
              onClickUnwrapGift={() => {}}
              onUnwrapAnimationComplete={() => {
                setShowHeader(true);
              }}
            />
          </Match>
          <Match when={true}>
            <GiftCardGenerationFailedComponent />
          </Match>
        </Switch>
      </Show>
    </>
  );

  function buildHeader() {
    return (
      <div class="fixed top-0 z-20 flex w-full flex-row items-center justify-between  bg-black  p-5 text-white">
        <A
          href={`/pluto/reveal/${props.giftingKey}/gift-details`}
          replace={true}
        >
          <PlutoCloseButton />
        </A>
        <PlutoBalancePill
          coinsAvailable={props.coinsCount}
          giftingKey={props.giftingKey}
          large={false}
          name=""
          setRefreshCoins={() => {}}
        />
      </div>
    );
  }

  function getVoucherDetails(): VoucherDetailResponse {
    const voucher = order()!.orderProductDetails.vouchers![0];
    return {
      amount: order()!.amount,
      cardNumber: voucher.cardNumber,
      expiryDate: voucher.expiryDate ?? "",
      pin: voucher.cardPin,
      redemptionType: voucher.voucherType as VoucherRedemptionType,
    };
  }
}
